// Cards
//$card-spacer-x:            1.25rem !default;
//$card-spacer-y:            .75rem !default;
//$card-border-width:        1px !default;
//$card-border-radius:       $border-radius !default;
$card-border-color: $gray-lighter !default; // #e5e5e5
//$card-border-radius-inner: $card-border-radius !default;
$card-bg: #fff !default;
$card-cap-bg: $card-bg !default; // #f5f5f5
//
//$card-link-hover-color:    #fff !default;
//
//$card-deck-margin:         .625rem !default;
